import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TicketTypeContent } from "src/components/atoms/TicketTypeContent";
import Footer from "src/components/molecules/Footer";
import HomeHeader from "src/components/molecules/Header";
import { useFormValuesContext } from "src/core/context";
import { getMonthString } from "src/core/helpers";
import { useGetSlots } from "src/core/queries/useGetSlots";
import { useGetSlotsMonthly } from "src/core/queries/useGetSlotsMonthly";
import style from "./styles.module.css";

export const PageTicketType = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const currentDate = new Date();
  const { data: slotsData, refetch: refetchSlots } = useGetSlots({
    id: formValues.order_type,
    date: formValues.date || currentDate,
    pos: "kostnice",
  });

  const { data: unavailableDays, refetch: refetchSlotsMonth } = useGetSlotsMonthly({
    id: formValues.order_type,
    month: getMonthString(formValues.date),
    visitors: formValues.ticketsCount,
    pos: "kostnice",
  });

  useEffect(() => {
    refetchSlots();
    refetchSlotsMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Box>
      <div className={style.header}>{isMobile ? null : <HomeHeader />}</div>
      <TicketTypeContent />
    </Box>
  );
};
